import React from "react";
import {
  UilInstagram,
  UilFacebook
} from "@iconscout/react-unicons";

function Footer() {
  return (
    <footer className="footer">
      <div className="footer__bg">
        <div className="footer__container container grid">
          <div>
            <h1 className="footer__title"><a href="#home" className="footer__title">
            Awadhesh Awasthi
            </a>
            </h1>
            <span className="footer__subtitle">Maintenance Manager, JTN Services Pvt. Ltd. Kanpur Nagar Nigam</span>
          </div>

          <ul className="footer__links">
            <li>
              <a href="#about" className="footer__link">
                About
              </a>
            </li>
            <li>
              <a href="#skills" className="footer__link">
                Work
              </a>
            </li>
            <li>
              <a href="#contact" className="footer__link">
                Contact
              </a>
            </li>
          </ul>

          <div className="footer__socials">
            <a
              href="https://www.facebook.com/awadesh.awasthibabbu"
              target="_blank"
              rel="noreferrer"
              className="footer__social"
            >
              <UilFacebook className="" />
            </a>
            <a
              href="https://www.instagram.com/awadeshawasthi4905/"
              target="_blank"
              rel="noreferrer"
              className="footer__social"
            >
              <UilInstagram className="" />
            </a>
          </div>
        </div>

        <a href="mailto:abhinav@crackdsa.com" >
        <p className="footer__copy">
        Made by Abhinav With ❤️&#174; All Right Reserved
        </p>
        </a>
      </div>
    </footer>
  );
}

export default Footer;
