import React, { useState } from 'react';
import AboutImg from "../images/about.jpg";
import AboutImg2 from "../images/about2.jpg";
import AboutImg3 from "../images/about3.jpg";
import VaneCV from "../assets/vanessapetitcv.pdf";
import { UilDownloadAlt } from '@iconscout/react-unicons'

function About() {
    const [yob,setyob]=useState(1968)

    return (
        <section className="about section" id="about">
        <h2 className="section__title">About Me</h2>
        <span className="section__subtitle">Let me introduce myself</span>

        <div className="about__container container">

            <div className="about__data">
            <img src={AboutImg} alt="" className="about__img" />
                <p className="about__description">
                <br></br>
                Welcome to the online presence of Awadhesh Awasthi. 
                <br></br>
                <br></br>
                <br></br>
                Born in {yob}, Awadhesh has dedicated his career to ensuring the smooth and efficient operation of facilities, earning a reputation as a reliable and skilled Manager.
                </p>
                <br></br>
                <br></br>
                <img src={AboutImg2} alt="" className="about__img2" />
                <p className="about__description">
                Awadhesh currently holds the position of Maintenance Manager at JTN Services Pvt Ltd, an esteemed company based in Kanpur Nagar Nigam. In this role, he oversees the maintenance and upkeep of vehicles, playing a vital role in the organization's success.
                <br></br>
                <br></br>
                <br></br>
                With a wealth of knowledge accumulated over the years, Awadhesh is well-versed in implementing best practices for maintenance, optimizing processes, and ensuring the longevity of equipment and systems.
                </p>
                <br />
                <img src={AboutImg3} alt="" className="about__img" />
                <p className="about__description">
                Beyond his professional life, Awadhesh is a devoted family man. He shares his life with his wife, Renu Awasthi, and their two accomplished children—a son excelling as a Software Engineer and a daughter diligently preparing for a career as a Company Secretary. 
                </p>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>

                {/* <div className="about__info">
                    <div>
                        <span className="about__info-title">15+</span>
                        <span className="about__info-name">Years <br/> experience</span>
                    </div>
                    
                    <div>
                        <span className="about__info-title">15+</span>
                        <span className="about__info-name">Years <br/> experience</span>
                    </div>

                    <div>
                        <span className="about__info-title">15+</span>
                        <span className="about__info-name">Years <br/> experience</span>
                    </div>
                </div> */}

                {/* <div className="about__buttons">
                    <a download="vanesssapetitcv" href={VaneCV} className="button button--flex">
                    Download CV<UilDownloadAlt className="button__icon" />
                    </a>
                </div> */}
            </div>
            
        </div>
        </section>
    )
}

export default About
